<template>
	<div class="card">
		<div class="list-assigned__header">
			<div>
				<p>Assigned Routes</p>

				<section class="btns" v-if="!edit">
					<button class="add btn" @click="open">+ Add routes</button>
					<button class="edit btn" @click="edit = true">Edit</button>
				</section>
				<button class="btn btn-primary" v-if="edit" @click="updatesChangesToRoutes">
					Done
				</button>
			</div>
		</div>
		<div class="routes">
			<p class="text-sm text-center my-4" v-if="!routes.length">No routes assigned to this event yet</p>
			<div v-for="route in routes" :key="route.id">
				<!-- <div class="routes" v-for="route in routes" :key="route.id">
      <div class="route-box">
        <p class="route-options text-sm">
          <span v-if="route.route_code">{{
            `${route.route_code} - `
          }}</span>
          {{ `${route.pickup} to ${route.destination}` }}
        </p>
          <i
          v-if="edit"
          class="fe fe-x"
          style="font-size: 24px; color: #E13D45"
          @click="deleteRoute(route.id)"
        ></i>
      </div>
    </div> -->
    
				<div class="route-box" v-if="route?.pickup">
					<p>
						<span v-if="route.routeCode">{{ `${route.routeCode} - ` }}</span>
						{{ `${route.pickup}` }}
					</p>

					<i
						v-if="edit"
						class="fe fe-x"
						style="font-size: 24px; color: #e13d45"
						@click="deleteRoute(route.id)"
						></i>
				</div>

				<!-- <div class="route-box" v-if="route?.dropoff">
					<p>
						<span v-if="route.routeCode">{{ `${route.routeCode} - ` }}</span>
						{{ `${route.dropoff}` }}
					</p>

					<i
						v-if="edit"
						class="fe fe-x"
						style="font-size: 24px; color: #e13d45"
						@click="deleteRoute(route.id)"
					></i>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    routes: Array,
    default: () => [],
  },
  data() {
    return {
      edit: false,
    }
  },
  emits: ['onEdited','onDelete'],
  methods: {
    open() {
      this.$bvModal.show('event-route-modal')
    },
    deleteRoute(id, action) {
      const routes = this.routes.filter((r) => r.id !== id)
      // const currentRoute = this.routes.find((r) => r.id == id)

      // if (action == 'pickup') {
      //   delete currentRoute.pickup
      // } else {
      //   delete currentRoute.dropoff
      // }
      this.$emit('onDelete', routes)
    },
    updatesChangesToRoutes() {
      this.edit = false
      this.$emit('onEdited', this.$props.routes)
    } 
   },
}
</script>
<style lang="scss" scoped>
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
.list-assigned__header {
  width: 100%;
  border-bottom: 1px solid #e5e9f2;
  margin-bottom: 20px;
}

.list-assigned__header div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  height: 55px;
}

.list-assigned__header div button {
  padding: 4px 12px;
}

.list-assigned__header div p {
  flex: 2;
}

.list-assigned__header div .edit {
  background: #4848ed;
  color: #fff;
}

.list-assigned__header div .add {
  border: 1px solid#101211;
}

.list-assigned__header div .btns {
  display: flex;
  gap: 12px;
}

.icon:hover {
  opacity: 50%;
}

.routes {
  padding: 0px 24px;
  max-height: 350px;
  overflow: auto;

}
.route-box {
  background: #f4f5f4;
  border-radius: 12px;
  display: flex;
  padding: 12px;
  /* padding-bottom: 0px; */
  margin: 12px 0px;

  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  font-size: 14px;

  p {
    width: 80%;
  }
}

.invisible {
  visibility: hidden;
}
</style>
